$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "./styles/scrollbar.scss";

/*************************************
  Global
**************************************/
@include scrollbars(.5em, #00a3e0); //custom scrollbar
::-ms-clear {
  display: none;
}

html, body {
  font-family: OpenSans, Arial, sans-serif;
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}

div#root{
  height: 100%;
  width: 100%;
}

input[type="text"], .form-control {
  border-color: darkgray;
  border-radius: .0rem;
  color: #000;
  background-color: #fff;
  border: 1px solid #00a3e0;
}

input[type="text"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: none;
  height: 29px;
  font-size: 14px;
}

/*************************************
  Color
**************************************/
.c-green {
  color: green;
}

.c-red {
  color: red;
}

.c-yellow {
  color: #ffcc00;
}


/*************************************
  Container-fluid
**************************************/

.container-fluid {
    padding: 0 0 50px 0;
    height: calc(100% - 50px);
}


/*************************************
  Navbar Component
**************************************/

.navbar,
.footer {
    border-radius: 0 !important;
    background-color: #101820;
    border-color: #101820;
}

#header-container-div {
  display: flex;
  min-width: 1000px;
  //white-space: nowrap;
}

.header-container
{
  flex-direction: column;
}

.header-col-2 {
  //display: inline-block;
  min-width: 500px;
  padding-right: 10px !important;
}

nav {
    .fa {
        font-size: 24px;
    }

    padding-top: 14px;
    padding-left: 3px;
}

.navbar-nav {
    margin: 0px;
}


/*************************************
  Navbar Toggle
**************************************/

.navbar-toggle {
    border: none;
    border-radius: 0px;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
    background-color: inherit;
}

.nav.navbar-nav li:hover {
    background-color: #eeeeee;
}

.navbar-brand {
    color: #fff !important;
    img {
        display: inline-block;
    }
}

.navbar-inverse .navbar-nav>li>a {
    color: #fff;
}

@media (min-width: 768px) {
    .navbar-nav>li>a {
        padding: 12px;
    }
}

.top-buffer {
    margin-top: 5px;
}

@media (min-width: 768px) {
    .navbar-nav>li>a {
        padding: 12px;
    }
}


/*****************************
  Tab
******************************/

.nav {
  min-width: 640px;
}

.nav-pills {
    li {
        a {
            color: #00A3E0;
            font-weight: bold;
            border-radius: 0px;
            text-transform: uppercase;
            margin: 0;
            border: 1px solid transparent;
        }
    }
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    color: #fff;
    background-color: #00a3e0;
    border: 1px solid #00a3e0;
}

.nav>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    border: 1px solid #00a3e0;
    background: transparent;
    color: #00a3e0;
}

.nav > li > a {
  padding: 5px 10px;
}

/*************************************
  CHR Flat Button
**************************************/

.btn {
    border-radius: 0px;
    border: 1px solid #00a3e0;
    background: #00a3e0;
    color: #fff;
    width: 100%;
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    &.btn-primary:hover {
        border: 1px solid #00a3e0;
        background: transparent;
        color: #00a3e0;
        border-bottom-width: 1px !important;
    }
    &.btn-primary:focus,
    .btn-primary:active {
        color: #fff;
        background-color: #204d74;
        border-color: #122b40;
    }
}

/*************************************
  Loading Indicator
**************************************/

.fullscreen-shader {
    position: fixed;
    z-index: 100000000;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
}

.fullscreen-centered {
    text-align: center;
    margin-top: 20%;
    color: black;
    font-size: x-large;
}

/*******************
  Table
********************/

tbody {
    height: calc(100vh - 350px) !important;
    overflow: auto;
    overflow-x: hidden;
}

thead,
tbody tr {
    width: 100%;
    table-layout: fixed;
}

td {
    vertical-align: middle !important;
}

thead {
    margin: 0px 3px 5px 3px;
    width: calc(100% - 1em);
}

.table {
    margin-bottom: 0px;
}

.table th:first-child {
  padding-left: 10px;
}

.table th:last-child {
  padding-right: 10px;
}

.table-cell-center {
  text-align: center;
}

.table-cell-right {
  text-align: right;
}

.table td:first-child {
  padding-left: 10px;
}

.table td:last-child {
  padding-right: 10px;
}

td.isCurrency {
  text-align: right !important;
}

/*******************
  Action Bar
********************/

.action-bar {
    height: 45px;
    width: 100%;
    min-width: 1400px;
    background-color: #eeeeee;
}

.action-list {
    list-style: none;
    display: inline-flex;
    padding-left: 10px;
}

.action-list-item {
    padding: 1px 28px;
    margin-top: 7px;
    padding-left: 5px;
}

select.form-control {
    border-radius: 5px;
    box-shadow: 0 1px 1px #4E99CD;
    margin-top: -5px;
    height: 34px;
}

header {
  background-color: white;
  display: inline-block;
  width: 100%;
}

/*******************
  Footer
********************/

.sticky-footer {
    background-color: #F3F3F3;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 50px;
    .sticky-footer-container {
        padding: 2px 8px;
        .btn-primary {
            margin-top: 5.5px;
            margin-right: 10px;
        }
    }
}


/*******************
  CHR Logo
********************/

.chr-logo-footer {
    padding-top: 8px;
    padding-left: 8px;
    color: black;
}

.pad-left-0 {
  padding-left: 0;
}

.pad-left-5 {
    padding-left: 5px;
}

.pad-left-10 {
    padding-left: 10px;
}

.pad-left-15 {
    padding-left: 15px;
}

.pad-right-0 {
  padding-right: 0;
}

.pad-right-5 {
  padding-right: 5px;
}

.pad-right-10 {
  padding-right: 10px;
}

.pad-right-15 {
  padding-right: 15px;
}

.vertical-align-middle {
    vertical-align: middle
}

.order-cutoff-time {
    font-weight: bold;
    color: #00A3E0;
    margin-top: 5px;
    padding-right: 10px;
}

.header-links {
    color: #00A3E0;
    padding-top: 14px;
    padding-right: 3px;
    font-weight:bold;
}

.header-links a {
  color: #00A3E0;
}

.header-links a:hover {
    color: #204D74;
}

.menu-bar {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 8px;
  min-width: 1000px;
}

.menu-bar div {
  //display: inline-block;
  height: 31px;
}

/*
  Pagination
*/

.pagination {
    margin: 0 0 6px 0;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 0px;
}

.dropdown {
  .btn {
    color: black;
    padding-top: 3px;
    height: 29px;
    background-color: transparent;
  }
  .dropdown-menu {
    display: none;
    &.expanded {
      display: block;
      border-radius: 0;
      cursor: pointer;
    }
  }
}

.custom-dropdown {
  margin-top: 0px;
  padding-top: 0px;
}

/*******************
Checkbox
********************/
$brand-success: #5cb85c;
$loader-size: 1.5em;
$check-height: $loader-size/2.2;
$check-width: $check-height/2.2;
$check-left: ($loader-size/9 + $loader-size/10);
$check-thickness: 2px;
$check-color: $brand-success;

.checkmark-circle {
  border: $check-thickness solid rgba(0, 0, 0, 0.2);
  animation-name: loader-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  &.checked {
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
    .checkmark {
      &:after {
        animation-duration: 800ms;
        animation-timing-function: ease;
        animation-name: checkmark;
        border-right: $check-thickness solid $check-color;
        border-top: $check-thickness solid $check-color;
        transform: scaleX(-1) rotate(135deg);
      }
    }
  }
}

.checkmark {
  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid rgba(0, 0, 0, 0.2);
    border-top: $check-thickness solid rgba(0, 0, 0, 0.2);
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
    transform: scaleX(-1) rotate(135deg);
  }
}

.checkmark-circle,
.checkmark-circle:after {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

/*******************
React-Datepicker
********************/

.react-datepicker {
  font-size: 1.4rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 1rem !important;
}

.datepicker-button {
  color: #0078AE;
  padding-top: 0px;
  height: 29px;
  border: 0;
  background-color: transparent;
  outline: none !important;
  &.btn-primary:hover {
    color: #204d74;
    border: 0;
  }
  &.btn-primary:focus{
      background-color: transparent;
      color: #204D74;
      box-shadow: none;
  }
}

.react-datepicker__navigation {
  top: 8px !important;
}

/*******************
Modal Overrides
********************/
.modal-header {
  border-bottom: 1px solid lightgray;
}

.modal-footer {
  border-top: 1px solid lightgray;
}

.modal-message {
  margin-left: 50px;
  display: inline-block;
}

.modal-icon {
  width: 42px;
  display: inline-block;
  position: absolute;
}

.modal-body {
  min-height: 69px;
}

/*******************
Panel Overrides
********************/

.panel {
  border-radius: 3px;
}

.panel-heading {
  border-radius: 3px 3px 0 0;
}

.panel-body {
  border-radius: 0 0 3px 3px;
}

/*******************
Order Summary
********************/

.order-summary div.col-sm-12,
.order-summary div.col-sm-12 p.col-sm-5,
.order-summary div.col-sm-12 p.col-sm-7,
.order-summary div.col-sm-12 div.col-sm-5,
.order-summary div.col-sm-12 div.col-sm-5 label
.order-summary div.col-sm-12 div.col-sm-7,
.order-summary div.col-sm-12 div.col-sm-7 label {
    padding-left: 0;
    padding-right: 0;
}

.order-summary div.col-sm-12 > input.textbox,
.order-summary div.col-sm-12 > textarea {
  margin-bottom: 10px;
}

.order-summary div.col-sm-12 div.col-sm-5 label.isCurrency {
  text-align: right;
}

.textbox {
  height: 28px;
  border: 1px solid #00A3E0;
}

.filter-textbox {
  padding: 6px;
  height: 29px;
  width: 280px;
  border: 1px solid #00A3E0;
}

/*******************
Actionbar Items
********************/

.actionbar-button {
  height:29px;
  padding-top:3px;
  color: #0078AE;
  background: transparent;
}


#addItemsModal {
  .modal-dialog {
    width:150rem;
  }
  .add-items-row {
    cursor: pointer;
  }
}

.numeric-textbox {
  width: 55px;
  height: 28px;
  border: 1px solid #00A3E0;
}

.pointer {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.header-logo {
  padding-top: 10px;
  padding-left: 10px;
}

#setDaysModal {
  .modal-dialog {
    width:80rem;
  }
  .modal-footer {
    .alert {
      padding: 6.5px;
      margin-top: 0px;
      margin-bottom: 0;
    }
  }
}

footer {
  z-index:100;
}

.main-content {
  height: calc(100% - 120px);
  padding-top: 1px;
}

.main-content-padding {
  padding-top: 1px;
}

.main-content-height {
  height: calc(100% - 160px);
}

.table-header-order-heightcalc
{
	height: calc(100vh - 303px) !important;
}

.table-nopaging-heightcalc
{
	height: calc(100vh - 343px) !important;
}

.table-paging-heightcalc
{
	height: calc(100vh - 390px) !important;
}

.center {
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 20%;
  top: 50%;
  transform: translateY(-50%);
}

.center30pct {
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 30%;
  top: 50%;
  transform: translateY(-50%);
}

.grey-background {
  background-color: #F5F5F5;
}

.rounded-top-border {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #DBDBDB;
  padding: 10px;
}

.rounded-bottom-border {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #DBDBDB;
  padding: 20px;
}

.send-email-button {
  background-color: #0078AE;
  border: 0;
  padding: 14px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
  outline:none;
}

.full-screen {
  height: 100vh;
}

.center-text {
  text-align: center;
}

.login-input {
  border-top-right-radius:4px !important;
  border-bottom-right-radius:4px !important;
  border-color: #D4D4D4 !important;
  box-shadow: none !important;
  height: 40px !important;
  display: block !important;
  margin : 0 auto !important;
  width: 90% !important;
}

#forgot-credentials-form {
  background: white;
  padding-bottom: 25px;
  & .input-group {
    padding-left: 8%;
  }
}

.toast {
  opacity: 1 !important;
}

//#region Clear Search
.text-input-wrapper {
  display:flex;
  flex: 1;
  z-index: 1;
}
div.text-input-clear {
  cursor:pointer;
  color:rgba(77, 77, 77, 0.337);
  font-weight:bold;
  font-size: 14px;
  z-index: 10;
  position: absolute;
  left: 270px;
  padding-top: 5px;
}
//#endregion

.setWidth50 {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

.setWidth60 {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}

.setWidth70 {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}

.setWidth80 {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}

.setWidth90 {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.setWidth100 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.setWidth120 {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.setWidth140 {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
}

.setWidth160 {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}

.setWidth180 {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.setWidth200 {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.setWidth300 {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.width60 {
  width: 60px;
  min-width: 60px;
}

.width80 {
  width: 80px;
  min-width: 80px;
}

.width90 {
  width: 90px;
  min-width: 90px;
}

.width100 {
  width: 100px;
  min-width: 100px;
}

.width120 {
  width: 120px;
  min-width: 120px;
}

.width160 {
  width: 160px;
  min-width: 160px;
}

.width200 {
  width: 200px;
  min-width: 200px;
}

.width300 {
  width: 300px;
  min-width: 300px;
}

.width400 {
  width: 400px;
  min-width: 400px;
}

.height_100percent {
  height: 100%;
}

.overflow_y_auto {
  overflow-y: auto;
}

.overflow_y_hidden {
  overflow-y: hidden;
}

.overflow_x_auto {
  overflow-x: auto;
}

.display_inline-grid {
  display: inline-grid;
}

.fixed_header thead {
  display: block;
  margin-bottom: 0px;
}


.fixed_header tbody {
  display: block;
  height: 100%;
  overflow-y: auto;
  margin-left: 3px;
  margin-right: 3px;
}

.fixed_header > tbody > tr > td {
  border-top: 0px;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.noPaging {
  height: calc(100vh - 262px) !important;
}

.noPaging-totalRow {
  height: calc(100vh - 294px) !important;
}

.table-noPaging-totalRow-heightcalc {
  height: calc(100vh - 334px) !important;
}

.tableWrapper {
  overflow: auto hidden;
  height: 100%;
  box-sizing: border-box;
}

.noItemsCell {
  border-bottom: 0px !important;
}

.min-width-1300 {
  min-width: 1300px;
}

.min-width-950 {
  min-width: 950px;
}

.inline-block {
  display: inline-block !important;
}

.spacer {
  padding-left: 4px;
  padding-right: 4px;
}

.top-bottom-pad-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.text-align-left {
  text-align: left;
}

.bannerContainer
{
  display:flex;
  flex-wrap: wrap;
  max-height: 40px;
  overflow: hidden;
}

.messageBanner {
  display: flex;
  min-width: 750px;
  flex-wrap: nowrap;
  justify-content: center;
  font-weight: bold;
  width: 100%;
}

.messageBanner .fa {
  padding-right: 10px;
  padding-top: 3px;
  padding-left: 10px;
}

.msg-high-priority {
  background-color: #da0303;
  color: #000000;
}

.msg-medium-priority {
  background-color: #f47b20;
  color: #000000;
}

.msg-low-priority {
  background-color: #e8af01;
  color: #000000;
}

.msg-Informational {
  background-color: #0078ae;
  color: #FFFFFF;
}

.icon-high-priority {
  color: #000000;
}

.icon-medium-priority {
  color: #000000;
}

.icon-low-priority {
  color: #000000;
}

.icon-informational {
  color: #FFFFFF;
}

.icon-order-informational {
  color: #0078ae;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  border-color: #0078ae;
  cursor: help;
  transition-delay: 0s;
  &.icon-invalid {
    color: #da0303;
  }
}

